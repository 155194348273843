<template>
    <b-modal :id="uniqueId" :ref="uniqueId" @hidden="handleClose">
        <template slot="modal-header" slot-scope="{ close }">
            <i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i>
            <h2 class="mx-auto my-auto">{{ $t('syndication.ajouter_porteurs_parts_moal_titre') }}</h2>
        </template>
        <template slot="default">
            <b-form>
                <div class="form-group">
                    <b-form-group :label="$t('syndication.type_saillie')">
                        <b-form-radio 
                            class="custom-control-inline"
                            name="saillie-type-radios" 
                            v-model="part.is_saillie_perso" 
                            :value="true"
                        >
                            {{ $t('syndication.saillie_perso') }}
                        </b-form-radio>
                        <b-form-radio 
                            class="custom-control-inline"
                            name="saillie-type-radios"
                            v-model="part.is_saillie_perso"
                            :value="false"
                        >
                            {{ $t('syndication.saillie_pool') }}
                        </b-form-radio>
                    </b-form-group>
                </div>
                <div class="form-group">
                    <label>{{ $t('syndication.type_facturation_part') }}</label>
                    <e-select
                        v-model="part.invoice_type"
                        id="invoicetype_id"
                        track-by="invoicetype_id"
                        label="invoicetype_labeltrad"
                        :selectedLabel="$t('global.selected_label')"
                        :options="syndic_invoice_types"
                        :searchable="true"
                        :allow-empty="false"
                        :loading="loading"
                        :show-labels="true"
                    >
                        <template slot="option" slot-scope="{ option }">{{ $t(option.invoicetype_labeltrad) }}</template>
                        <template slot="singleLabel" slot-scope="{ option }">{{ $t(option.invoicetype_labeltrad) }}</template>
                    </e-select>
                </div>

                <div class="form-group">
                    <label>{{ $t('syndication.ajouter_porteurs') }}</label>
                    <e-select
                        v-model="part.tiers"
                        track-by="tiers_id"
                        label="tiers_rs"
                        :placeholder="$t('syndication.selectionner_tiers_porteurs')"
                        :selectedLabel="$t('global.selected_label')"
                        :options="tiers"
                        :searchable="true"
                        :allow-empty="false"
                        :loading="loading"
                        :show-labels="true"
                        :multiple="true"
                    />
                </div>

                <div v-if="part.tiers.length > 0" class="form-group">
                    <label>{{ $t('syndication.repartition_porteurs') }}</label>
                    <div class="mb-3" v-for="(tier, idx) in part.tiers" :key="'tier_percent_'+idx">
                        <b-input-group :prepend="tier.tiers_rs" append="%">
                            <b-form-input v-model="part.tiers[idx].percentage" type="number" step="0.01" min="0.01" max="100"></b-form-input>
                        </b-input-group>
                    </div>
                </div>
            </b-form>
        </template>

        <template slot="modal-footer" class="justify-content-center">
            <b-button variant="primary" @click="checkForm()" :disabled="!formComplete">
                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
                {{ $t('global.modifier') }}
            </b-button>
        </template>
    </b-modal>
</template>


<script type="text/javascript">
    import Gynecologie from "@/mixins/Gynecologie.js"
    import Syndic from "@/mixins/Syndic.js"
    import Tiers from "@/mixins/Tiers.js"

	export default {
        name: "EditPartModal",
        mixins: [Gynecologie, Syndic, Tiers],
        props: {
            submitForm: { type: Function, default: () => false },
			syndic_invoice_types: { type: Array, default: () => [] }
        },
        data() {
            return {
                tiers: [],
                loading: true,
                processing: false,
                temp_part: null,

                part: {
                    id: 0,
                    tiers: [],
                    is_saillie_perso: 1,
                    invoice_type: null
                }
            }
        },
        computed: {
            uniqueId() {
                return 'modal-edit-part-'+this._uid
            },
            formComplete() {
                return this.part.tiers.length > 0
                    && !this.hasInvalidPercentage
            },
            hasInvalidPercentage() {
                let result = false
                let total = 0

                this.part.tiers.forEach(tier => {
                    if(!tier.percentage || tier.percentage < 0 || tier.percentage > 100) {
                        result = true
                        return
                    }

                    total += parseFloat(tier.percentage)
                })

                return result || total > 100
            }
        },
		watch: {
			'syndic_invoice_types' (val) {
				this.part.invoice_type = val[0]
			}
		},
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                let tab_promises = []
				this.part.invoice_type = this.syndic_invoice_types.length > 0 ? this.syndic_invoice_types[0] : null
                
                tab_promises.push(new Promise((rsv, _) => {
                    this.loadTiers()
                        .then(res => {
                            res.forEach((tier, idx) => {
                                res[idx].percentage = 0
                            })
                            this.tiers = res
                        })
                        .finally(rsv())
                }))

                await Promise.all(tab_promises)
                this.loading = false
            },

            checkForm() {
                if(this.formComplete) {
                    this.submitForm({
                        syndicpart_id: this.part.id,
                        tiers: this.part.tiers.map(tier => { 
                            return { 'id': tier.tiers_id, 'percentage': tier.percentage }
                        }),
                        is_saillie_perso: this.part.is_saillie_perso,
                        is_saillie_pool: !this.part.is_saillie_perso,
                        invoice_type: this.part.invoice_type.invoicetype_id
                    })
                }
            },

            openModal(part) {
                // Pour ne pas casser la réactivité, on cherche à retrouver les tiers à partir des ids de la part, dans tous les tiers récupérés
                const tiers_ids = part.syndicpart_tiers.map(tierpart => tierpart.tierpart_tier.tiers_id)
                const tiers = this.tiers.filter(tier => tiers_ids.indexOf(tier.tiers_id) > -1)

                // On reprend les pourcentages
                const tiers_percentage = part.syndicpart_tiers.map(tierpart => {
                    return {
                        id: tierpart.tierpart_tier.tiers_id,
                        percentage: tierpart.tierpart_percentage
                    }
                })
                tiers.forEach(tier => {
                    tier.percentage = tiers_percentage.find(t => t.id == tier.tiers_id).percentage
                })

                // On set le formulaire
                this.part.id = part.syndicpart_id
                this.part.tiers = tiers
                this.part.is_saillie_perso = part.syndicpart_is_perso === true
                this.part.invoice_type = part.syndicpart_invoice_type
                this.$bvModal.show(this.uniqueId)
            },

            closeModal() {
                this.$bvModal.hide(this.uniqueId)
            },

            handleClose() {
                console.log("handleClose")
            }
        }
	}
</script>
